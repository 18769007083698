@import "@/assets/scss/main.scss";

.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.tab-header {
  margin-bottom: 12px;
  .tab-title {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .tab-desc {
    color: $grey-6-mayday;
    font-size: 12px;
  }

  .tab-button {
    margin-top: 20px;
  }

  .bold {
    font-weight: bold;
  }
}
