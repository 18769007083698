@import "@/assets/scss/main.scss";

.integration-settings-attributes {
  &__table {
    :deep() .el-table__body-wrapper {
      max-height: 50vh;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &__cta {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
