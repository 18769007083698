@import "@/assets/scss/main.scss";

.slider {
  width: 70%;
  margin-left: 4px;
  margin-right: 4px;
}
.placeholder-value {
  font-size: 12px;
  color: $grey-6-mayday;
}
:deep() .el-slider__button-wrapper {
  z-index: 0 !important;
}
