@import "@/assets/scss/main.scss";

.workflow-auto-step-selection {
  &__step-logic {
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__label {
        padding: 4px 14px 4px 8px;
        background-color: white;
        border: 1px solid $grey-2-mayday;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        &__icon {
          border: 1px solid $grey-2-mayday;
          border-radius: 2px;
          height: 28px;
          width: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          &__img {
            height: 16px;
            width: auto;
          }
        }
        &__step {
          align-self: center;
          font-size: 16px;
          font-weight: bold;
          color: black;
        }
      }
      &__trash:hover {
        color: $red-mayday;
        cursor: pointer;
      }
    }
  }

  &__condition {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }
}
