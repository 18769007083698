@import "@/assets/scss/main.scss";

.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.tab-header {
  margin-bottom: 10px;
  .tab-title {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  .tab-desc {
    color: $grey-6-mayday;
    font-size: 12px;
    font-weight: bold;
  }
}

.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 8px 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
