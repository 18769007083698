@import "@/assets/scss/main.scss";

.workflow-set-context-from-select {
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__item {
      flex: 12;
    }
    &__delete {
      flex: 1;
      margin-top: 16px;
      cursor: pointer;
      color: $grey-5-mayday;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  &__add {
    margin: 4px 4px;
  }
}
